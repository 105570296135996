@import url('https://fonts.cdnfonts.com/css/product-sans');

::-webkit-scrollbar {
    display: none;
}

html {
    scrollbar-width: none;
}

/*
html:hover{
    animation: rotateY 2s infinite linear;
}
*/

#main{
    width: 100%;
    height: 100%;

    display: flex;
    overflow: hidden;
    background-image: url("../Components/Images/leaderboardbackground.png");
    background-position: center;
    background-size: cover;
}

#home{
    width: 100%;
    height: 100%;

    background-image: url("../Components/Images/leaderboardbackground.png");
    background-position: center; 
    background-size: cover;   
}

#header{

    display: flex;
    align-items: center;
    margin-top: 2%;
    margin-left: 2%;

    width: 100%;
    height: 10%;

    top: 1%;
}

.nameplate{
    width: fit-content;
    max-width: 30%;
    height: 10%;

    display: flex;
    align-items: center;
    border: 2px solid black;
    background-color: rgba(187, 176, 176, 0.7);
    overflow: hidden;
    margin: 1%;
    position: absolute;
    top: 5%;
    right: 3%;
}

.nameplate-info{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2.5%;
}

.nameplate-info p{
    font-size: 200%;
    font-family: 'Product Sans', sans-serif;
    font-weight: 500;
}

.product_svg text{
    animation: animated_name 5s infinite linear;
}

.border{
    width: fit-content;
    height: 50%;
    padding: 1%;
    border: 5px ridge black;
    margin-right: 5%;
}

.pp{
    width: auto;
    height: 100%;
}

.level-holder{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.score-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.score-container img{
    width: 25%;
    height: auto;

    margin-left: -50%;
    margin-right: 5%;

    transform-style: preserve-3d;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
}

.score-container img:hover{
    animation: rotateY 1s infinite linear;
}


.content-container{
    display: flex;
    width: 100%;
    height: 100%;
}

.chart-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 90%;
    margin-left: 5%;
    padding: 5%;
}

canvas{
    display: grid;
    place-items: center;
    margin-top: -12.5%;
    height: 30%;
}

.chart{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar {
    left: 0%;
    top: 0%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 5%;
    transition: width 0.75s ease-in-out;
    background-color: rgba(182, 243, 213, 0.8);
    margin-right: 5%;
}

.sidebar:hover {
    width: 20%;
}

.menu-func-container {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    transition: 0.3s ease-in-out;
}

.menu-top{
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    top: 1%;
    position: absolute;
}

.menu-top img{
    position: absolute;
    right: 1%;
    height: 100%;
    width: auto;
    transition:  0.5s ease-in-out;
}

.sidebar .menu-func-container img {
    height: 30%;
    width: auto; 
    margin-right: 5%;
}

.sidebar button {
    display: none;
    font-size: 40%;
    width: 60%;
    height: 20%;

    transition: ease-in-out 0.3s;
}

.sidebar:hover button {
    display: block;
    background-color: transparent;
    color: black;
    font-size: 90%;
    font-family: 'Product Sans', sans-serif;
    border: none;
    overflow: hidden;

    height: 70%;
}

.sidebar:hover .menu-top img{
    rotate: 90deg;
}

.sidebar button:hover {
    font-size: 100%;
}

.menu-func-container:has(button:hover){
    background-color: rgba(41, 129, 87, 1);
}

.data-form{
    display: grid;
    place-items: center;
}

.data-title{
    color: black;
    position: static;
    margin-top: -125%;
}

.back{
    position: absolute;
    left: 0.3%;
    top: 2.5%;

    width: 5%;
    height: 5%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
    border: none;
}

.back img{
    width: 75%;
    height: auto;
    transition: 0.5s ease-in-out;
}

.back img:hover{
    cursor: pointer;
}

.title-background{
    background-color: rgba(41, 129, 87, 0.9);

    width: fit-content;
    height: 50%;

    display: grid;
    place-items: center;
    padding-right: 5%;
    padding-left: 5%;
    font-size: 200%;
    box-shadow: 2px 2px 2px 2px black;
}

.table-container{
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    background-image: url("../Components/Images/leaderboardbackground.png");
    background-position: center;
    overflow: hidden;
}

.table-container h1{
    font-family: 'Product Sans', sans-serif;
    color: aliceblue;
}

.table{
    width: 100%;
    height: 70%;
    display: grid;
    place-items: center;
    overflow-y: scroll;
}

.row {
    display: flex;
    align-items: center;
    margin: 1%;
    width: 60%;
    height: fit-content;
    font-size: 100%;
    box-shadow: 2px 2px 2px 2px black;
    background-color: rgba(41, 129, 87, 0.9);

}

.place {
    flex: 1;
    font-family: 'Product Sans', sans-serif;
}

.name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-family: 'Product Sans', sans-serif;
}

.score {
    font-family: 'Product Sans', sans-serif;
    flex: 1;
}

.name svg text{
    animation: animated_name 5s infinite alternate;
}

@media screen and (max-width: 1600px){
    .chart{
        height: 40%;
    }

    .nameplate{
        width: fit-content;
        max-width: 40%;
        width: auto;
    }

    .nameplate .product_svg {
        font-size: 250%;
    }
}

@media screen and (max-width: 768px){
    #main{
        overflow: hidden;
        width: 100vw;
        height: 100vh;
    }

    #home{
        width: 100%;
        height: 100%;
    }

    .nameplate{
        width: fit-content;
        min-width: none;
        max-width: 80%;
    }

    .nameplate-info h2{
        font-size: 200% !important;
        margin-left: -15%;
        overflow: hidden;
    }

    .nameplate-info .product_svg text{
        animation: animated_name 5s infinite linear;
        font-size: 70%;

    }

    .nameplate-info .product_svg{
        margin-left: -10%;
        overflow: hidden;
    }

    .chart-container{
        width: 85%;
        height: 90%;
        margin-left: 5%;
    }

    .sidebar{
        width: 15%;
        height: 100vh;
    }

    .sidebar:hover{
        font-size: 105%;
        width: 40%;
    }

    .row{
        width: 80%;
        height: 70px;
        max-height: 70px;
        font-size: 50%;
        overflow: hidden;
    }
    
    .name{
        height: 100%;
        width: 1000px !important;
        min-width: none;
        max-width: none;
    }

    .score{
        align-self: center;
    }

    svg{
        width: 100% !important;
    }

    .name h2, .name svg text{
        font-size: 300%;
    }

    .back{
        height: 10%;
        width: 15%;
        margin-top: -5%;
    }

    .title-background{
        width: 75%;
        height: 50%;
        font-size: 150%;
        margin-top: 20%;
    }
}

@keyframes rotateY {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

@keyframes animated_name {
    0%   {
        fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1);
        stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
    }
    70%  {fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1); }
    80%  {fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1); stroke-width: 2; }
    100% {
        fill: rgba(0, 0, 0, 1); stroke: rgba(54,95,160,0);
        stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
    }
}

@media screen and (max-width: 768px){
    @keyframes animated_name {
        0%   {
            fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1);
            stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
        }
        70%  {fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1); }
        80%  {fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1); stroke-width: 2; }
        100% {
            fill: rgba(0, 0, 0, 1); stroke: rgba(54,95,160,0);
            stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
        }
    }
}