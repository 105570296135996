.toggle-switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -25%;
  top: 15%;
  
}
  
.toggle-switch {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.option {
  padding: 10px 20px;
  transition: background-color 0.3s;
  color: #818181;
  cursor: pointer;
}

.option.active {
  background-color: rgba(182, 243, 213, 0.8);
  color: #fff;
  cursor: default;
}

.switch {
  width: 40%;
  height: 40%;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.toggle-switch-container .toggle-switch:hover .switch {
  transform: scale(1.1);
}
  

@media screen and (max-width: 768px){
  .toggle-switch-container{
    left: -7.5%;
    top: 25%;
  }
}