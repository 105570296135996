#shop{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.offer-container{
    height: 90%;
    width: 50%;
    overflow: scroll;
    padding-top: 5%;
}

header{
    width: 100%;
    height: 10%;
    margin-bottom: 60%;
}

header h1{
    color: rgba(182, 243, 213, 0.8);
    font-size: 250%;
    display: flex;
    align-items: center;
    justify-content: center;
}

header .nameplate{
    top: 1%;
}

.card{
    width: 100%;
    height: 50%;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer{
    width: 48%;
    height: 100%;
    display: grid;
    place-items: center;
    margin: 1%;
    background-color: rgba(182, 243, 213, 0.8);
    border: 3px ridge black;
    box-shadow: 0px 0px 4px 3px rgba(255, 255, 255, 0.8);
}

.offer-content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
}

.offer button img{
    height: auto;
    width: 12.5%;
    margin-left: 5%;
}

.offer button{
    width: 80%;
    height: fit-content !important;
    background-color: transparent;
    border: none;
    align-self: last baseline;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.3s;
    margin-bottom: 5%;
    font-size: 150%;
}

.offer button:hover{
    background-color: rgba(41, 129, 87, 0.5);
    border-radius: 5px;
}

.product{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: baseline;

    width: 100%;
    height: 100%;
}

.product svg text{
    animation: animated_name 5s linear infinite !important;
}

.price{
    font-weight: 550;
    font-size: 100%;
}

.product h1{
    color: black;
    font-size: 150%;
}

.offer-content h1{
    font-size: 150%;
}

@media screen and (max-width: 768px){
    .title{
        margin-top: 20%;
        margin-bottom: -10%;
    }

    .offer-container{
        width: 90%;
    }

    .card{
        width: 100%;
    }

    .offer:first-child{
        margin-right: 3% !important;
    }

    .offer{
        width: 45%;
        justify-content: center;
    }

    .product{
        justify-content: center;
        align-items: center;
    }

    svg{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .offer-content .product svg text, .offer-content .product h2{
        font-size: 190% !important;
        align-self: center;
    }

    .offer-content .product h2{
        margin-top: 35%;
    }

    text{
        width: 100%;
        height: 100%;
    }
}