@import url('https://fonts.cdnfonts.com/css/product-sans');

h1 {
    font-family: 'Product Sans', sans-serif;
    font-size: 250%;
    font-weight: 550;
    width: 100%;
   
}

#loginpanel h1,
.loginpanel h1{
    color: black;
}

h2 {
    font-family: 'Product Sans', sans-serif;
    font-weight: 500;
}

h3{
    font-family: 'Product Sans', sans-serif;
    font-weight: 350;
    margin-top: -20%;
    margin-bottom: 10%;
}

.data-container{
    background-image: url("../Components/Images/leaderboardbackground.png");
}

#container {
    width: 100%;
    height: 100vh;
    display: flex;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
}

#loginpanel, .loginpanel {
    width: 35%;
    height: 75%;
    display: grid;
    background-color: rgba(182, 243, 213, 0.8);
    place-items: center;
    border-radius: 5%;
    overflow: hidden;
    box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.5);
}

.register {
    font-family: 'Product Sans', sans-serif;
    font-size: 100%;
    height: fit-content;
    border: none;
    background-color: transparent;
    color: rgb(1, 134, 211);
}

#register{
    margin-top: -40%;
    margin-bottom: 5%;
    font-size: 150%;
}

.terms{
    margin-top: 2.5%;
}

#form {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.form{
    width: 50%;
    display: grid;
    place-items: center;
}

.forgor-form{
    padding-top: 25%;
}

.register:hover {
    cursor: pointer;
}

input::placeholder {
    font-family: 'Product-sans', sans-serif;
    font-size: 70%;
    transform: translateY(-10%);
    transition: ease-in-out 0.15s;
}


fieldset{

    width: 100%;
    font-size: 130%;
    margin-bottom: 2.5%;
    padding: 0%;
    border: none;
    border-radius: 10px;
    transition: ease-in-out 0.5s;
}

fieldset legend{
    display: none;
    text-align: left;
    
}

fieldset:focus-within{
    border: 1px solid rgba(33, 131, 106, 1);
    font-size: 150%;
    
}


fieldset:focus-within legend {
    display: grid;
    font-size: 70%;
    color: rgba(33, 131, 106, 1);
}

fieldset:focus-within input{
    border: none;
}

fieldset:focus-within input::placeholder{
    color: transparent;
}

.inputbox {
    font-size: 160%;
    max-width: 90%;
    height: fit-content;
    padding-left: 5%;
    text-align: left;
    outline: none;
    background-color: transparent;
    border: 1px solid grey;
    border-radius: 10px;
    color: grey;
}

#forgor {
    margin-bottom: -10%;
    margin-top: -20%;
}

#submit {
    
    background-color: rgb(182, 243, 235);
    border: none;
    border-radius: 15px;
    margin-top: 5%;
    width: 95%;
    max-width: 95%;
    min-width: fit-content;
    height: 50px;
    font-size: 180%;
    margin-bottom: 35%;
    transition: ease-in-out 0.5s;
}

#submit:hover {
    transform: scale(1.05);
    box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.5);
    
}

.dropdown{
    width: 25%;
    height: 150%;

    outline: 1px solid black;
    text-align: left;
    background-color: rgb(182, 243, 235);
}

.dropdown select {
    display: none;

}

.boxlabel{
    font-size: 120%;
    font-family: 'Product-sans', sans-serif;
}

.motto{
    align-self: baseline;
    justify-self: center;
    position: absolute;
    font-size: 250%;
    font-weight: 500;
    font-family: "monospace";
    margin-top: -0%;
}

@media screen and (max-width: 1600px) {

    .loginpanel h1{
        font-size: 250%;
        margin-bottom: -70%;
    }

    #loginpanel, .loginpanel{
        width: 30%;
        height: 80%;
        max-width: none;
        overflow: hidden;
        
    }

    #container{
        width: 100%;
        height: 100vh;
        max-width: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #form{
        width: 80%;
        max-width: none;
    }
    

    #submit{
        width: 75%;
        max-width: none;
        font-size: 110%;
        margin-top: 0%;
        margin-bottom: 20%;

    }

    .registerh1{
        position: absolute;
        top: 5%;
        margin-top: 3%;
    }

    #registerform{
        margin-bottom: -25%;
    }

    .register{
        margin-top: -15% !important;
        font-size: 100% !important;
    }

    #registerbutton{
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    #specifich1{
        margin-bottom: 10%;
    }


    .terms{
        margin-top: 0% !important;
        margin-bottom: 5% !important;
    }

    .inputbox{
        width: 80%;
    }
    
    fieldset{
        margin: 0%;
        font-size: 100%;
    }

    fieldset:focus-within{
        max-width: 95%;
        margin-left: 2.5%;
        font-size: 100%;
    }

    .motto{
        align-self: baseline;
        justify-self: center;
    }

    .register{
        margin: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
    }

    #forgor{
        margin-bottom: 0%;
    }
}


@media screen and (max-width: 768px) {
    
    h1{
        font-size: 250%;
    }

    #container{
        width: 100%;
        max-width: none;
        height: 100vh;

        display: grid;
        place-items: center;
    }

    #loginpanel, .loginpanel{
        width: 85%;
        height: 95%;
        max-width: none;
        display: grid;
        place-items: center;
        border-radius: 25px;
    }

    .registerh1{
        margin-top: -35%;
        margin-bottom: 10%;
    }

    #form {
        width: 80%;
        height: 60%;
        font-size: 110%;
    }

    #registerform{
        margin-top: 35%;
    }

    #submit {
        width: 80%;
        max-width: none;
        font-size: 170%;
        height: 6vh;        
    }

    .inputbox{
        width: 75%;
        font-size: 150%;
        margin-bottom: -10%;
    }

    #forgor{
        margin-top: 20% !important;
    }
    
    #registerbutton{
        margin-bottom: -20% !important;
        margin-top: -20% !important;
    }

    #register{
        margin-top: -40%;
        font-size: 150% !important;
    }

    fieldset{
        margin: 0%;
    }

    #specific form{
        margin-top: -40%;
    }

    #specifich1{
        font-size: 200%;
        margin-top: -70%;
    }

    .text{
        margin-top: -3%;
        margin-bottom: 2.5%;
        font-family: 'Product Sans', sans-serif;
    }

    .dropdown{
        margin-bottom: 10%;
        width: 35%;
        font-size: 150%;
    }

} 

