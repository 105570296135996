@import url('https://fonts.cdnfonts.com/css/product-sans');

h1{
    color: white;
    font-size: 350%;
}

label{
    margin-top: 5%;
    font-size: 200%;
}

button{
    margin-top: -5%;
}

.pp-button{
    margin-top: 0%;
}

.back{
    margin-top: 0%;
}

.profile-container{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.profile {
    width: 30%;
    height: 80%;
    overflow: scroll;

    border: 4px ridge black;
    box-shadow: 3px 3px 2px 2px grey;

    background-color: rgba(0, 0, 0, 0.4);
}

.icon{
    place-self: center;
    width: 30%;
    height: auto;
}

.profile-name{
    display: grid;
    place-items: center;
    margin-bottom: 10%;
}

.profile-name h1{
    color: white;
}

.profile-name button{
    background-color: transparent;
    border: none;
}

.profile-name button img{
    width: 10%;
    height: auto;
}

.gradient{
    background: #121FCF;
    background: linear-gradient(to right, #2833ce 0%, #CF1512 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 350%;
    place-self: center;
}

.row-style{
    display: grid;
    place-items: center;

    width: 100%;
    height: 30%;
}

.row-type{
    display: grid;
    place-items: center;
    margin: 1%;
    width: 70%;
    height: 50%;
    font-size: 200%;
    box-shadow: 2px 2px 2px 2px black;
    background-color: rgba(41, 129, 87, 0.9);

}

.row-style button{
    background-color: transparent;
    border: none;
}

.row-style button img{
    width: 10%;
    height: auto;
}

.selector{
    width: 30%;
    height: 80%;
    overflow: scroll;

    border: 4px ridge black;
    box-shadow: 3px 3px 2px 2px grey;

    background-color: rgba(0, 0, 0, 0.7);
}

.nameoption{
    width: 100%;
    height: 55%;
    margin-bottom: 5%;
    display: grid;
    place-items: center;
    position: relative;
    align-self: center;
}

.green-gradient{
    background: rgb(0,255,0);
    background: linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,0,0,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 350%;
    place-self: center;
}

svg text{
    animation: stroke 5s infinite alternate;
	stroke-width: 3;
	stroke: #365FA0;
    font-size: 350%;
}

.name-style-form{
    width: 100%;
    height: 100%;
}

.select-color{
    width: 100%;
    height: 40%;
    margin-top: -20%;
    color: white;
}

.select-color input{
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    outline: none;
    border: none;
    box-shadow: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
    height: 100%;
    width: 100%;
}

input[type = "submit"] {
    width: 450px;
    height: 50px;
    max-width: 70%;
    margin-top: 0%;
    border-radius: 5px;
    background-color: rgba(41, 129, 87, 0.9);
    font-size: 150%;
}

input[type = "submit"]:hover{
    box-shadow: 1px 1px 2px 2px grey;
}

@keyframes stroke {
	0%   {
		fill: rgba(72,138,204,0); stroke: rgba(128, 128, 128, 1);
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
    50%{fill: rgba(72,138,204,0); stroke: rgba(128, 128, 128, 1); stroke-width: 4;}
	70%  {fill: rgba(72,138,204,0); stroke: rgba(128, 128, 128, 1); }
	80%  {fill: rgba(72,138,204,0); stroke: rgba(128, 128, 128, 1); stroke-width: 3; }
	100% {
		fill:rgba(128, 128, 128, 1); stroke: rgba(128, 128, 128, 1);;
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
	}
}