@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url('https://fonts.cdnfonts.com/css/product-sans');


html{
    font-family: 'Product Sans', sans-serif;
}

.product h2:nth-child(1){
    background: #121FCF; 
    background: linear-gradient(to right, rgb(18, 31, 206) 0%, #CF1512 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 300%;
    place-self: center;
}

h6{
    align-self: baseline;
}

.product:first-child svg text{
    animation: stroke 5s infinite alternate;
	stroke-width: 3;
	stroke: #365FA0;
    color: black;
}

.hex{
    width: 60%;
    height: auto;
}


.discount img{
    width: 25%;
    height: auto;
}














@keyframes stroke {
	0%   {
		fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1);
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1); }
	80%  {fill: rgba(72,138,204,0); stroke: rgba(0, 0, 0, 1); stroke-width: 3; }
	100% {
		fill: rgba(0, 0, 0, 1); stroke: rgba(54,95,160,0);
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
	}
}