@import url('https://fonts.cdnfonts.com/css/product-sans');

.tip{
    color: black;
    font-size: 250%;
    font-family: 'Product Sans', sans-serif;

}

.toggle-switch-container-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 2.5%;
    
}
    
.toggle-switch-tip{
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;

}
  
.option {
    padding: 10px 20px;
    transition: background-color 0.3s;
    color: #818181;
    cursor: pointer;

}
  
.option.active {
    background-color: rgba(182, 243, 213, 0.8);
    color: #fff;
    cursor: default;

}

.switch {
    width: 40%;
    height: 40%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;

}

.toggle-switch-container .toggle-switch:hover .switch {
    transform: scale(1.1);

}

.tip-container{
    border-radius: 20px;
    width: 60%;
    height: 70%;
    background-color: rgba(182, 243, 213, 0.8);
    margin-left: 20%;
    margin-top: 5%;
    display: grid;
    place-items: center;

}

.refbutton{
    background-color: transparent;
    border: none;
    width: 5%;
    height: auto;
    position: absolute;
    bottom: 7.5%;
    left: 47.5%;
}

.refbutton img{
    width: 100%;
    height: auto;
    
}

.refbutton:hover {
    animation: rotateZ 2s linear infinite;
    transform-origin: center center;
}




@keyframes rotateZ {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

@media screen and (max-width: 768px){
    .toggle-switch-container{
        left: -7.5%;
        top: 25%;
        
    }

    .tip{
        font-size: 200%;
    }

    .refbutton{
        width: 15%;
        margin-bottom: 10%;
    }

    .tip-container{
        width: 80%;
        height: 90%;
        margin-left: 10%;
    }
}
